import React, { useState } from 'react';
import SplashScreen from './components/SplashScreen.js';
import Navbar from './components/Navbar.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Projects from './components/Projects.js';
import Experience from './components/Experience.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import Certificates from './components/Certifications.js';

function App() {
  // const [showSplash, setShowSplash] = useState(true);

  // const handleSplashDone = () => {
  //   setShowSplash(false);
  // };

  return (
    <div className="App">
      {/* <SplashScreen onDone={handleSplashDone} /> */}
      {/* <div className={`main-content ${showSplash ? 'hidden' : ''}`}> */}
      <div>
        <Navbar />
        <Home />
        <About />
        {/* <Experience /> */}
        <Certificates />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}
export default App;
