import React, { useEffect } from 'react';
import AOS from 'aos';
import cp from "../assets/aws-certified-cloud-practitioner.png"
import codepath from "../assets/code path logo.png"

const CertificationCard = ({ imageSrc, title }) => (
  <div className="flex flex-col items-center">
    <img src={imageSrc} alt={title} className="w-32 h-32 object-cover mb-4" />
    <h3 className="text-lg font-bold text-gray-700">{title}</h3>
  </div>
);

const Certificates = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const certifications = [
    {
      title: 'AWS Certified Cloud Practitioner',
      imageSrc: cp,
    },
    {
      title: 'Advanced Web Development Certificate',
        imageSrc: codepath,
      
    },
  ];

  return (
      <div id='certifications' className="flex flex-col bg-gray-100 p-10 md:p-16 lg:p-20 xl:p-24 items-center  shadow-xl border-b border-gray-300 shadow-md" style={{ scrollMarginTop: "64px",  }}>
        <h1 className="text-4xl font-bold text-center mb-12 ">Certifications</h1>
      <div className="grid grid-cols-2 md:grid-cols-2 text-center ">
        {certifications.map(cert => (
          <CertificationCard key={cert.title} imageSrc={cert.imageSrc} title={cert.title} />
        ))}
      </div>
    </div>
  );
}

export default Certificates;
